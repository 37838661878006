export const rabbitTokenAddress = "0xb649585488014F2A429374a280d557C38D74C4C7";
export const rabbitTokenAbi = [{
    "inputs": [{
        "internalType": "contract IUniswapV2Router02",
        "name": "_uniswap",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "_usdt",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "_marketingWallet",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "_buybackWallet",
        "type": "address"
    }],
    "stateMutability": "nonpayable",
    "type": "constructor"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "target",
        "type": "address"
    }],
    "name": "AddressEmptyCode",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "account",
        "type": "address"
    }],
    "name": "AddressInsufficientBalance",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "allowance",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "needed",
        "type": "uint256"
    }],
    "name": "ERC20InsufficientAllowance",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "sender",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "needed",
        "type": "uint256"
    }],
    "name": "ERC20InsufficientBalance",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "approver",
        "type": "address"
    }],
    "name": "ERC20InvalidApprover",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "receiver",
        "type": "address"
    }],
    "name": "ERC20InvalidReceiver",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "sender",
        "type": "address"
    }],
    "name": "ERC20InvalidSender",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }],
    "name": "ERC20InvalidSpender",
    "type": "error"
}, {
    "inputs": [],
    "name": "FailedInnerCall",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }],
    "name": "OwnableInvalidOwner",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "account",
        "type": "address"
    }],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "token",
        "type": "address"
    }],
    "name": "SafeERC20FailedOperation",
    "type": "error"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
    }],
    "name": "Approval",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "buyTax",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "holdersTax",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "marketingTax",
        "type": "uint256"
    }],
    "name": "BuyTaxUpdated",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
    }],
    "name": "OwnershipTransferred",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "uint256",
        "name": "sellTax",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "holdersTax",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "marketingTax",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "buybackTax",
        "type": "uint256"
    }],
    "name": "SellTaxUpdated",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
    }],
    "name": "Transfer",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "address",
        "name": "oldPair",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "address",
        "name": "newPair",
        "type": "address"
    }],
    "name": "UniswapV2pairUpdated",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "address",
        "name": "Wallet",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "isexcluded",
        "type": "bool"
    }],
    "name": "excludeFromFeeAddressUpdated",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "contract IERC721",
        "name": "oldContract",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "contract IERC721",
        "name": "newContract",
        "type": "address"
    }],
    "name": "nftContractUpdated",
    "type": "event"
}, {
    "inputs": [],
    "name": "USDT",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }],
    "name": "allowance",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "spender",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
    }],
    "name": "approve",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "account",
        "type": "address"
    }],
    "name": "balanceOf",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "burningWallet",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "buybackWallet",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "claimReward",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "decimals",
    "outputs": [{
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "account",
        "type": "address"
    }, {
        "internalType": "bool",
        "name": "excluded",
        "type": "bool"
    }],
    "name": "excludeFromFee",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "getBuyTax",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "account",
        "type": "address"
    }],
    "name": "getRewardBalance",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "getSellTax",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "lastRewardTime",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "marketingWallet",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "name",
    "outputs": [{
        "internalType": "string",
        "name": "",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "nftContract",
    "outputs": [{
        "internalType": "contract IERC721",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "owner",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "rewardCycleInterval",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "contract IERC721",
        "name": "_nftcontract",
        "type": "address"
    }],
    "name": "setNftcontract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "bool",
        "name": "onoff",
        "type": "bool"
    }],
    "name": "setSwapEnabled",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "swapEnabled",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "symbol",
    "outputs": [{
        "internalType": "string",
        "name": "",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
    }],
    "name": "transfer",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "from",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
    }],
    "name": "transferFrom",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
    }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "uniswapV2Pair",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "uniswapV2Router",
    "outputs": [{
        "internalType": "contract IUniswapV2Router02",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "buyTaxToHolders",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "buyTaxToMarketing",
        "type": "uint256"
    }],
    "name": "updateBuyTax",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "sellTaxToHolders",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "sellTaxToMarketing",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "sellTaxToBuyback",
        "type": "uint256"
    }],
    "name": "updateSellTax",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "_uniswapV2Pair",
        "type": "address"
    }],
    "name": "updateUniswapV2Pair",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}]